/* DeckSelector.css */
.deck-selector-container {
  /* Hide default scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 2px; /* Firefox */
}

.deck-selector-container::-webkit-scrollbar {
  display: 2px; /* Chrome, Safari and Opera */
}

/* Custom scrollbar styles for WebKit browsers */
.deck-selector-container::-webkit-scrollbar {
  height: 7px; /* Change height for horizontal scrollbar */
}

.deck-selector-container::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(8, 60, 98, 0.721187850140056) 0%,
    rgba(99, 58, 116, 0.7519441526610644) 40%,
    rgba(68, 57, 110, 0.7018881302521008) 70%,
    rgba(8, 60, 98, 0.70211221988795518) 100%
  );
  border-radius: 10px;
}

.deck-selector-container::-webkit-scrollbar-thumb {
  background: white;
  border: 2px solid grey;
  border-radius: 10px;
}

.deck-selector-container::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
}
