/* General Styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "HelveticaNeue-Light", sans-serif;
}

html,
body,
#root {
  height: 100%;
  max-height: 100%;
  width: 100%;
}

html {
  background-color: rgb(11, 59, 96)
}

.carousel-root {
  max-width: 400px;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  box-sizing: inherit;
}

/* Button Styles */
.button {
  margin: 10px 0;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  width: 80%;
  text-align: center;
  padding: 10px;
  font-size: 1.2rem;
  letter-spacing: 5px;
  width: 100%;
}

.button:hover {
  opacity: 0.9;
}

/* Specific Button Styles */
.google-button {
  background: white;
  color: black;
  border: 1px solid #ccc;
}

.apple-button {
  background: white;
  color: black;
  border: 1px solid #ccc;
}

.email-button {
  background: #e91e63;
  color: white;
  border: none;
}

.outline-button {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.outline-button:hover {
  background: white;
  color: black;
}

/* Input Styles */
.input {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  width: 80%;
  max-width: 300px;
}

@media (min-width: 768px) {
  .input {
    font-size: 1.2rem;
  }
}

/* Form Styles */
.form {
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

/* Link Styles */
.link {
  color: #e91e63;
  font-size: 1.2rem;
  font-weight: bolder;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  text-decoration-color: #e91e63;
}

.link:visited {
  font-weight: bolder;
  text-decoration-color: #e91e63;
}

/* Title Styles */
.title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .title {
    font-size: 2rem;
  }
}

/* Subtitle Styles */
.subtitle {
  margin-top: 20px;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .subtitle {
    font-size: 1.2rem;
  }
}

.container {
  background: linear-gradient(180deg,
      rgba(176, 31, 141, 0.921187850140056) 0%,
      rgba(99, 58, 116, 0.6519441526610644) 40%,
      rgba(68, 57, 110, 0.5018881302521008) 70%,
      rgba(8, 60, 98, 0.10211221988795518) 100%),
    url("../images/intro_background.png") no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 100%;
  max-width: 400px;
  width: 100vw;
  color: black;
}

.page-section {
  padding: 0 14px;
}

.signup-content {
  width: 100%;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  height: 100%;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* POLICY AND TERMS */

.terms-and-policy-container {
  color: black;
  font-family: '"HelveticaNeue-Light"', sans-serif;
  background: none;
}

.text-container,
.terms-and-policy-container p {
  text-align: left;
}

.terms-background {
  background: linear-gradient(180deg,
      rgba(203, 136, 205, 0.42875087535014) 0%,
      rgba(225, 142, 238, 0.42875087535014) 13%,
      rgba(97, 89, 235, 0.3718881302521008) 31%,
      rgba(209, 116, 237, 0.4279105392156863) 44%,
      rgba(94, 135, 191, 0.36544555322128855) 63%,
      rgba(144, 128, 208, 0.2718881302521008) 78%,
      rgba(74, 138, 184, 0.13211221988795518) 100%);
  padding: 20px;
}



@media (max-width: 320px) {
  .homePage {
    padding: 0 !important;
  }

  .deck-selector-container {
    height: 200px !important;
  }

  .deckTitle {
    font-size: 1.5rem !important;
    padding-left: 10px !important;
  }

  .checkoutTitle {
    font-size: 15px;
  }

  .entryJournalHeader {
    font-size: 15px !important;
  }
}

@media (max-height: 670px) {
  .checkoutContainer {
    overflow: scroll;
    height: 70vh;
  }

  .journalContainer {
    overflow: scroll;
    height: 73vh !important;
  }

  .entryJournalHeader {
    font-size: 15px !important;
  }

  .journal-circle {
    display: none;
  }

  .journal-mobile-circle {
    display: flex!important;
  }

  .journal-mobile-circle-item {
    border-radius: 50%;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 10px;
  }
}

.journal-mobile-circle {
  display: none;
}