.slide {
  position: relative;
  text-align: center;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.text-overlay {
  text-align: center;
  color: white;
  width: 80%;
}

.text-overlay-left {
  text-align: left;
  color: white;
  width: 80%;
}

.text-overlay.top {
  margin-top: 20px;
}

.text-overlay.middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  /* Adjust padding to account for the icon */
}

.text-overlay.bottom {
  margin-bottom: 40px;
}

.title {
  font-size: 1rem;
  margin: 0;
}

.subtitle {
  font-size: 1rem;
  margin: 10px 0;
  line-height: 0px;
  letter-spacing: 2px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.logo {
  height: 50px;
  margin: 0 10px;
}

.icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
}

@media (min-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .icon {
    width: 70px;
  }
}

