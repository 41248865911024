
.card-container {
    height: 100%;
    width: 100%;
    perspective: 900px;
}

.card {
    height: 100%;
    width: 100%;
    position: relative;
    transition: transform 1500ms;
    transform-style: preserve-3d;
    cursor: pointer;
   
}

.card.flipped {
    transform: rotateY(180deg);
}


.card-front-side,
.card-back-side {
    height: 100%;   
    width: 100%;
    border-radius: 2rem;
    position: absolute;
    backface-visibility: hidden;
}

.card-back-side {
    transform: rotateY(180deg);
}