.shop-cards-container {
    margin-top: 16px;
  }
  
  .shop-card-stack {
    position: relative;
    width: 110px;
    height: 150px;
    margin: auto;
  }
  
  .shop-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    left: -14px;
  }

  .shop-card-image-main {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  .shop-card1 {
    z-index: 3;
    transform: translate(0, 0);
    transform: rotate(-7deg);
  }
  
  .shop-card2 {
    z-index: 2;
    transform: translate(15px, -5px) rotate(7deg);
  }
  
  .shop-card3 {
    z-index: 1;
    transform: translate(20px, -5px) rotate(20deg);
  }
  
  .shop-card-title {
    text-align: center;
    margin-top: 8px;
    font-weight: bold;
  }

   /* ==============>>>> DECKS PREVIEW <<<============ */

   .preview-containter {
    transition: all 0.5s ease-in-out;
   }



