.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 12px 12px 0 0;
  border: none;
}

.ql-editor {
  max-height: 40vh;
  min-height: 40vh;
  border-radius: 12px 12px 0 0;
  border-top: 1px solid rgba(205, 205, 205, 0.996);
  flex: 1;
  overflow-y: auto;
  width: 100%;
  flex-grow: 1;
  background-color: transparent;
}

@media (min-height: 800px) {
  .ql-editor {
    max-height: 50vh;
    min-height: 50vh;
  }
}

.ql-toolbar {
  background-color: #f0f0f0; /* Change this color to whatever you like */
}


.journal-circle {
    position: absolute;
    bottom: 120px;
    border-radius: 50%;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 10px;
  }
  
  .journal-circle:hover {
    transform: scale(0.9);
  }





