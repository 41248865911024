@font-face {
  font-family: 'Nexa Bold';
  src: url('./fonts/Nexa\ Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: '"HelveticaNeue-Light"';
  src: url('./fonts/Nexa\ Light.otf') format('opentype');
  font-weight: normal;
}

body, html {
  font-family: '"HelveticaNeue-Light"', sans-serif;
}
